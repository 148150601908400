import BasicLayout from '@/layouts/BasicLayout'
import UserLayout from '@/layouts/UserLayout'
import BlankLayout from '@/layouts/BlankLayout'

// 在页面中动态加载当前页面所匹配到的组件
const RouteView = {
  name: 'RouteView',
  render: (h) => h('router-view')
}
export const constantRouterMap = [
  {
    path: '/',
    component: UserLayout,
    redirect: '/login',
    children: [
      {
        path: '/login',
        name: 'login',
        component: () => import('@/views/user/Login')
        
      },
      {
        path: '/auth',
        name: 'auth',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/Auth')
      },
    ]
  },
  {
    path: '/mainTab',
    name: 'mainTab',
    component: BasicLayout,
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: 'home',
        component: () => import('@/views/home/home'),
      }
    ]
  },

  // {
  //   path: '/mainTab',
  //   name: 'mainTab',
  //   component: BlankLayout,
  //   redirect: '/home',
  //   children: [
  //     {
  //       path: '/home',
  //       name: 'home',
  //       component: RouteView,
  //       redirect: '/home',
  //       children: [
  //         {
  //           path: '/home',
  //           name: 'home',
  //           component: () => import('@/views/home/home'),
  //         },

  //         {
  //           path: '*', redirect: '/404', hidden: true
  //         }
  //       ]
  //     },
  //   ]
  // },


  {
    path: '/404',
    component: () => import(/* webpackChunkName: "fail" */ '@/views/exception/404')
  }

]