<template>
  <div id="userLayout" :class="['user-layout-wrapper']">
    <div class="top">
      <div class="header">
        <a href="/">
          <!-- <img src="http://pic.downcc.com/upload/2018-2/2018225934278929.png" class="logo" alt="logo"> -->
          <img src="~@/assets/logo.png" class="logo" alt="logo">
        </a>
      </div>
      <div class="desc">
        <h1>云南腾云信息产业有限公司</h1>
      </div>
    </div>
    <router-view />
    <!-- <div style="width: 300px; height: 200px; background-color: red; position: absolute; top: 30%;"></div> -->
    
  <!-- <div class="footer">
    <span>ssdf剩下的方式</span>
  </div> -->

    <div class="footer">
      <div class="copyright">
        Copyright &copy; 云南腾云信息产业有限公司
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'UserLayout',
  mounted () {
    document.body.classList.add('userLayout')
  },
  beforeDestroy () {
    document.body.classList.remove('userLayout')
  }
}
</script>

<style lang="scss" scoped>
.user-layout-wrapper{
    min-height: 100vh;
    background: #f0f2f5 url(~@/assets/background.svg) no-repeat 50%;
    background-size: 100% 100%;
    width: 100%;

    .top {
      text-align: center;
      padding: 12vh 0 0vh;
      width: 100%;
      height: 10%;

      .header {
        height: 44px;
        line-height: 44px;
        .logo {
          height: 44px;
          vertical-align: top;
          margin-right: 16px;
          border-style: none;
        }
      }
      .desc {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.45);
        margin-top: 12px;
        margin-bottom: 40px;
      }
    }

    /* 底部样式 */
    .footer {
      height: 60px;
      line-height: 60px;
      text-align: center;
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      background-color: #f7f8fa;
      border-top: #dedfe1 1px solid;
      .copyright {
        color: rgba(0, 0, 0, 0.45);
        font-size: 14px;
      }
    }
}

</style>
