<template>
  <a-layout id="components-layout-demo-custom-trigger">
    <!-- <a-layout-sider v-model="collapsed" :trigger="null" collapsible>
      <div class="logo" />
      <a-menu theme="dark" mode="inline" :default-selected-keys="['1']">
        <a-menu-item key="1">
          <a-icon type="user" />
          <span>应用管理</span>
        </a-menu-item>
        <a-menu-item key="2">
          <a-icon type="video-camera" />
          <span>角色管理</span>
        </a-menu-item>
        <a-menu-item key="3">
          <a-icon type="upload" />
          <span>用户管理</span>
        </a-menu-item>
      </a-menu>
    </a-layout-sider> -->

    <a-layout style="height:auto">
      <a-layout-header>
        <div class="height-left">
          <img src="https://t-suite-login.ybsjyyn.com/img/logo.76c7e262.png" alt="">
          <span>云南腾云信息化管理系统</span>
        </div>
        <a-dropdown class="height-right">  
          <a class="ant-dropdown-link">
            <a-avatar :size="24" src="https://gw.alipayobjects.com/zos/antfincdn/XAosXuNZyF/BiazfanxmamNRoxxVxka.png" />
            <!-- <span>Serati Ma</span> -->
            <span>{{name}}</span>
          </a>
          <a-menu slot="overlay" class="menu-item" mode="inline">
            <!-- <a-menu-item>
              <a-icon type="user" />
              <span>个人中心</span>
            </a-menu-item>
            <a-menu-item>
              <a-icon type="setting" />
              <span>个人设置</span>
            </a-menu-item> -->
            <a-menu-item @click="logout">
              <a-icon type="logout" />
              <span>退出登录</span>
            </a-menu-item>
          </a-menu>
        </a-dropdown> 
      </a-layout-header>

      <a-layout-content
        :style="{ margin: '24px 16px', padding: '24px', background: '#fff', minHeight: '87vh'}"
      >
        <!-- 杨子栋 -->
        <router-view></router-view>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>
<script>
import  config from '@/config/modeUrl'
import store from '@/store'
export default {
  name: 'layout',
  data() {
    return {
      collapsed: false,
      name:''
    };
  },
  created(){
    var user=this.$store.getters.userInfo;
    this.name=user.name
  },
  methods: {
    logout(){
      //清除redis，然后清除前端相关数据，再跳转到登录页
    store.dispatch('Logout').then((res) => {
      setTimeout(() => {
        window.location.href = config.VUE_APP_outLoginUrl
      }, 500)
    })
    }
  }

};
</script>
<style lang="scss" scoped>

#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.ant-layout-header{
  background: #fff; 
  padding: 0;
  // display: inline-flex;
}

.height-left{
  float: left;
  width: 60%;
  margin-left: 62px;
  img{
    width: 40px;
    height: 40px;
    margin-bottom: 10px;
  }
  span{
    font-size: 19px;
    margin-left: 16px;
  }
}

.height-right{
  float: right;
  margin-right: 8px;
  span{
    margin-right: 8px;
  }
  .ant-avatar{
    margin: calc((64px - 24px) / 2) 0;
    margin-right: 8px;
    color: #1890ff;
    vertical-align: top;
    background: rgba(255, 255, 255, 0.85);
  }
  span{
    color: black;
  }
  cursor: pointer;
}

.ant-dropdown-link:hover{
  background-color: rgba(0, 0, 0, 0.025);
}

.ant-dropdown-link{
  padding-left: 8px;
}
.menu-item{
  span{
    margin-right: 8px;
  }
}
</style>
