const modeUrlObj={
    'production':{
        baseUrl:'https://it.yntengyun.com/author/',
        VUE_APP_outLoginUrl:'https://it.yntengyun.com/portal/',
        WX_QRLoginUrl:'http://it.yntengyun.com/portal/home',
        WX_AuthLoginUrl:'http://it.yntengyun.com/portal/login',
        WX_AppId:'ww4da2a659884e6294',
        WX_AgentId:'1000096',
    },
    'development':{
        // baseUrl:'http://192.168.15.67:8080/author/',
        // VUE_APP_outLoginUrl:'http://192.168.15.67:8090/portal/',
        // WX_QRLoginUrl:'http://192.168.15.67:8090/portal/home'

        baseUrl:'http://192.168.15.67:8080/author/',
        VUE_APP_outLoginUrl:'http://192.168.15.67:8090/portal/',
        WX_QRLoginUrl:'http://192.168.15.67:8090/portal/home',
        WX_AppId:'ww4da2a659884e6294',
        WX_AgentId:'1000096',
    },
    'test':{
        baseUrl:'https://t-it.yntengyun.com/author/',
        VUE_APP_outLoginUrl:'https://t-it.yntengyun.com/portal/',
        WX_QRLoginUrl:'http://t-it.yntengyun.com/portal/home',
        WX_AuthLoginUrl:'http://t-it.yntengyun.com/portal/login',
        WX_AppId:'ww4da2a659884e6294',
        WX_AgentId:'1000090',
    }
}

export default modeUrlObj[process.env.NODE_ENV]