import originAxios from 'axios'
import qs from 'qs'
import  config from '@/config/modeUrl'
import store from '@/store'
//不设置这个，用axios请求不会携带cookie
originAxios.defaults.withCredentials = true;

// export default function axios(option) {
	const request = function axios(option) {
		return new Promise((resolve, reject) => {
			// 1.创建axios的实例
			const instance = originAxios.create({
				baseURL: config.baseUrl,
				timeout: 50000,
				dataType : 'json',
			});
			
		// 配置请求和响应拦截
		instance.interceptors.request.use(config => {
			// 与TIO总集那边配合，需要请求头中设置异步
			config.headers['X-Request-Type-Ajax'] = '1'


			// 3.对请求的参数进行序列化(看服务器是否需要序列化)
			if(config.method==='GET'||config.method==='get'){

			}else if(config.method==='POST'||config.method==='post'){
				config.data = qs.stringify(config.data)
			}
			// 4.等等
			return config
		}, err => {
			// console.log('来到了request拦截failure中');
			return err
		})
		instance.interceptors.response.use(response => {
			// console.log('来到了response拦截success中');
			const res = response.data
			return Promise.resolve(res)
		}, err => {
			console.log('来到了response拦截failure中');
			if (err && err.response) {
				switch (err.response.status) {
					case 400:
						err.message = '请求错误'
						break
					case 401:
						//未授权直接跳转到登录页面
						err.message = '未授权的访问'
						window.location.href = config.VUE_APP_outLoginUrl
						break
				}
			}
			return err
		})

		// 2.传入对象进行网络请求
		instance(option).then(res => {
			//根据传回的状态码做解析
			if(res.code==200||res.code==0){
				resolve(res)
			}else{
				reject(res)
			}
		}).catch(err => {
			console.log(err)
			reject(err)
		})
	})
}
export default request