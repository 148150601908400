import router from './router'
import store from './store'

const allowList = ['login', 'auth']
import config from '@/config/modeUrl'

router.beforeEach((to, from, next) => {
    // redirect_uri，授权登陆后的回调地址，需要进行encodeURIComponent处理
    let redirect_uri = encodeURIComponent(config.WX_AuthLoginUrl)
    let appid = "ww4da2a659884e6294"

    //判断当前入口是PC端浏览器还是PC端企业微信
    let entrance = isQyweixin()
    if (entrance === '来自于PC端的企业微信') {
        //白名单内不做登录判断，直接next
        if (to.query.code && to.path == '/login') {
            store.dispatch('loginByQr', to.query.code).then(res => {
                console.log('获取用户信息')
                store.dispatch('GetInfo').then(res => {
                    // next()
                    next('/home')
                })
            })
        } else if (!to.query.code && to.path == '/login') {
            // 没有code 要去企业微信获取
            // 未授权,去登录
            // 在企业微信请求连接时，在连接中添加参数connect_redirect=1，即可规避重复访问的情况
            console.log('没有code 要去企业微信获取')
            window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_base&state=STATE&connect_redirect=1#wechat_redirect`
        } else if (typeof store.getters.userInfo.id === 'undefined') {
            //强制刷新时重新获取用户信息
            store.dispatch('GetInfo').then(res => {
                next()
            })
        } else {
            next()
        }
    } else if (entrance === '来自于PC端浏览器') {
        //如果是跳转无需验证的路由，直接通过
        if (allowList.includes(to.name)) {
            next();
        } else if (to.query.code && to.path == '/home') {
            //如果是扫码登录，进行如下操作
            console.log('开始验证扫码登录')
            store.dispatch('loginByQr', to.query.code).then(res => {
                console.log('获取用户信息')
                store.dispatch('GetInfo').then(res => {
                    // next()
                    next('/home')
                })
            })
        } else if (typeof store.getters.userInfo.id === 'undefined') {
            //强制刷新时重新获取用户信息
            store.dispatch('GetInfo').then(res => {
                next()
            })
        } else {
            next()
        }
    }
})

function isQyweixin() {
    //判断当前入口是PC端还是APP端
    let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    ) == null ? false : true;

    return flag ? is_weixin() ? "来自于APP端企业微信" : "" : is_weixin() ? "来自于PC端的企业微信" : "来自于PC端浏览器";
}

function is_weixin() {
    //判断是在企业微信打开 还是 在浏览器打开
    return navigator.userAgent.toLowerCase().indexOf('micromessenger') !== -1
}

