// import request from '@/network/axios'
import request from '@/network/axios'

const userApi = {
  LoginByVCode: '/user/userAuth/loginByVCode',
  LoginByQrCode: '/user/userAuth/ewmLogin',
  Logout: '/loginOut',
  SendSms: '/user/userAuth/sendVerCode',
  SendSmsErr: '/account/sms_err',
  // get my info
  UserInfo: '/user/getPortalUserInfo',
  UserMenu: '/user/nav',
}

 /* 通过短信验证码校验 */
export function LoginByVCode (parameter) {
  return request({
    url: userApi.LoginByVCode,
    method: 'get',
    params:parameter,
  })
}

/* 通过二维码登录 */
export function LoginByQrCode (parameter) {
  return request({
    url: userApi.LoginByQrCode,
    method: 'get',
    params:parameter,
  })
}

/* 发送短信验证码 */
export function getSmsCaptcha (parameter) {
  return request({
    url: userApi.SendSms,
    method: 'get',
    params:parameter,
  })
}

/* 获取用户信息 */
export function getUserInfo () {
  return request({
    url: userApi.UserInfo,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

/* 用户退出登录 */
export function logout (parameter) {
  return request({
    url: userApi.Logout,
    method: 'get',
    params:parameter,
  })
}
