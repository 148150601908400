import { getInfo, logout } from '@/network/user/login'
import {LoginByQrCode,getUserInfo} from '@/network/user/login'

const user = {
  state: {
    info: {}
  },

  mutations: {
    SET_INFO: (state, info) => {
      state.info = info
    },
  },

  actions: {
    // 获取用户信息
    GetInfo ({ commit }) {
      return new Promise((resolve, reject) => {
        getUserInfo().then(res => {
          console.log('成功获取到用户信息', res.data)
          //将用户信息存到vuex中
          commit('SET_INFO',res.data)
          resolve(res)
        })
      })
    },
    //二维码登录验证
    loginByQr({ commit }, code){
      var parameter={
        code:code
      }
      return new Promise((resolve, reject) => {
        LoginByQrCode(parameter).then(res => {
          console.log('二维码验证成功', res)
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 登出
    LogoutLocal ({ commit, state }) {
      return new Promise((resolve) => {
          commit('SET_INFO', '')
          commit('SET_ROLES', [])
          commit('SET_TOKEN', '')
          storage.remove(ACCESS_TOKEN)
          resolve()
      })
    },

    // 登出
    Logout ({ commit, state }) {
      return new Promise((resolve) => {
        logout(state.token).then((res) => {
          commit('SET_INFO', '')
          resolve(res)
        }).catch((err) => {
          reject(err)
        }).finally(() => {
        })
      })
    },

  }
}

export default user
